body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.site-container {
  min-height: 100vh;
}
main {
  flex: 1;
}
.btn-primary,
.btn-outline-primary {
  background-color: #f0c040;
  color: #000000;
}
.products {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.rating span {
  color: #ffc000;
}

.product {
  border: 1px #404040 solid;
  margin: 1rem;
}
.product img {
  width: 100%;
  max-width: 400px;
}
.product-info {
  padding: 1rem;
}
.img-large {
  max-width: 100%;
}
.img-thumbnail {
  height: 80px;
}
.small-container {
  max-width: 600px;
}

.checkout-steps > div {
  border-bottom: 0.2rem solid #a0a0a0;
  color: #a0a0a0;
}
.checkout-steps > div.active {
  border-bottom: 0.2rem solid #f08000;
  color: #f08000;
}

/* sidebar */
.navbar-brand {
  font-weight: bold;
}
.side-navbar {
  width: 240px;
  height: 100%;
  position: absolute;
  left: -300px;
  background-color: #100901;
  transition: 0.5s;
}

.site-container {
  transition: 0.4s;
}
.active-cont {
  margin-left: 240px;
}
.active-nav {
  left: 0;
}
.text-bold {
  font-weight: bold;
}

/* Map */

.full-box {
  height: 100vh;
  max-width: initial;
}
.full-box header,
.full-box footer {
  display: none;
}
.full-box .container {
  height: 100vh;
  max-width: initial;
  margin: 0;
  padding: 0;
}

.full-box .mt-3 {
  margin-top: 0 !important;
}

.map-input-box {
  box-sizing: border-box;
  position: absolute;
  left: 0;
  right: 0;
  margin: 0.5rem auto;
  width: 25rem;
  height: 3rem;
  display: flex;
}
.map-input-box input {
  border-radius: 1rem 0 0 1rem;
  border-right: 0;
}
.map-input-box button {
  border-radius: 0 1rem 1rem 0;
  border-left: 0;
}
button.thumbnail {
  padding: 0;
}
